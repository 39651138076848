import React from 'react'
import TopItems from './TopItems'

export default function index() {
    return (
        <div>
            <TopItems />
        </div>
    )
}
