import React, { useState } from 'react'
// import {Tabs, Tab, Nav, Row, Col} from 'react-bootstrap'

export default function TopItems() {

    const [menu, setMenu] = useState(0); 

    const changeMenu = ind => e => {
        e.preventDefault();
        setMenu(ind)
    }

    return (
        <React.Fragment>
            


<div class="content-top ">
	<div class="container ">
        <div class="tab-head ">
            <nav class="nav-sidebar">
                <ul class="nav tabs">
                    <li class={menu === 0 ? 'active': ''}><a href="#tab1" onClick={changeMenu(0)} data-toggle="tab">Staples</a></li>
                    <li class={menu === 1 ? 'active': ''}><a href="#tab2" onClick={changeMenu(1)} data-toggle="tab">Snacks</a></li> 
                    <li class={menu === 2 ? 'active': ''}><a href="#tab3" onClick={changeMenu(2)} data-toggle="tab">Fruits & Vegetables</a></li>  
                    <li class={menu === 3 ? 'active': ''}><a href="#tab4" onClick={changeMenu(3)} data-toggle="tab">Breakfast & Cereal</a></li>
                </ul>
            </nav>
            <div class=" tab-content tab-content-t ">
                { menu === 0 &&
                <div class="tab-pane active text-style" id="tab1">
                    <div class=" con-w3l row">
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">								
                                <a href="#" data-toggle="modal" data-target="#myModal1" class="offer-img" >
                                    <img src="images/of.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Moong</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$2.00</label><em class="item_price">$1.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b " data-id="1" data-name="Moong" data-summary="summary 1" data-price="1.50" data-quantity="1" data-image="images/of.png">Add to Cart</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal2" class="offer-img">
                                    <img src="images/of1.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Sunflower Oil</a>(5 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$10.00</label><em class="item_price">$9.00</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="2" data-name="Sunflower Oil" data-summary="summary 2" data-price="9.00" data-quantity="1" data-image="images/of1.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal3" class="offer-img">
                                    <img src="images/of2.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Kabuli Chana</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$3.00</label><em class="item_price">$2.00</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                        <div class="add">
                                            <button class="button-outline my-cart-btn my-cart-b" data-id="3" data-name="Kabuli Chana" data-summary="summary 3" data-price="2.00" data-quantity="1" data-image="images/of2.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal4" class="offer-img">
                                    <img src="images/of3.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Soya Chunks</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$4.00</label><em class="item_price">$3.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="4" data-name="Soya Chunks" data-summary="summary 4" data-price="3.50" data-quantity="1" data-image="images/of3.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                { menu === 1 &&
                <div class="tab-pane active text-style" id="tab2">
                    <div class=" con-w3l row">
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal5" class="offer-img">
                                    <img src="images/of4.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Lays</a>(100 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$1.00</label><em class="item_price">$0.70</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="5" data-name="Lays" data-summary="summary 5" data-price="0.70" data-quantity="1" data-image="images/of4.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal6" class="offer-img">
                                    <img src="images/of5.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Kurkure</a>(100 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$1.00</label><em class="item_price">$0.70</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="6" data-name="Kurkure" data-summary="summary 6" data-price="0.70" data-quantity="1" data-image="images/of5.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal7" class="offer-img">
                                    <img src="images/of6.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Popcorn</a>(250 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$2.00</label><em class="item_price">$1.00</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="7" data-name="Popcorn" data-summary="summary 7" data-price="1.00" data-quantity="1" data-image="images/of6.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal8" class="offer-img">
                                    <img src="images/of7.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Nuts</a>(250 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$4.00</label><em class="item_price">$3.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="8" data-name="Nuts" data-summary="summary 8" data-price="3.50" data-quantity="1" data-image="images/of7.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>		  
                </div>
                }
                { menu === 2 &&
                <div class="tab-pane active text-style" id="tab3">
                    <div class=" con-w3l row">
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                            <a href="#" data-toggle="modal" data-target="#myModal9" class="offer-img">
                                    <img src="images/of8.png" class="img-responsive" alt="" />
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Banana</a>(6 pcs)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$2.00</label><em class="item_price">$1.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="9" data-name="Banana" data-summary="summary 9" data-price="1.50" data-quantity="1" data-image="images/of8.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal10" class="offer-img">
                                    <img src="images/of9.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Onion</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$1.00</label><em class="item_price">$0.70</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="10" data-name="Onion" data-summary="summary 10" data-price="0.70" data-quantity="1" data-image="images/of9.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal11" class="offer-img">
                                    <img src="images/of10.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html"> Bitter Gourd</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$2.00</label><em class="item_price">$1.00</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="11" data-name="Bitter Gourd" data-summary="summary 11" data-price="1.00" data-quantity="1" data-image="images/of10.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal12" class="offer-img">
                                    <img src="images/of11.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Apples</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$4.00</label><em class="item_price">$3.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="12" data-name="Apples" data-summary="summary 12" data-price="3.50" data-quantity="1" data-image="images/of11.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>		  
                </div>
                }
                { menu === 3 &&
                <div class="tab-pane active text-style" id="tab4">
                        <div class=" con-w3l row">
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                            <a href="#" data-toggle="modal" data-target="#myModal13" class="offer-img">
                                    <img src="images/of12.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Honey</a>(500 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$7.00</label><em class="item_price">$6.00</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="13" data-name="Honey" data-summary="summary 13" data-price="6.00" data-quantity="1" data-image="images/of12.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m ">
                                <a href="#" data-toggle="modal" data-target="#myModal14" class="offer-img">
                                    <img src="images/of13.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Chocos</a>(250 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$5.00</label><em class="item_price">$4.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="14" data-name="Chocos" data-summary="summary 14" data-price="4.50" data-quantity="1" data-image="images/of13.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m ">
                                <a href="#" data-toggle="modal" data-target="#myModal15" class="offer-img">
                                    <img src="images/of14.png" class="img-responsive" alt=""/>
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Oats</a>(1 kg)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$4.00</label><em class="item_price">$3.50</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="15" data-name="Oats" data-summary="summary 15" data-price="3.50" data-quantity="1" data-image="images/of14.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 m-wthree">
                            <div class="col-m">
                                <a href="#" data-toggle="modal" data-target="#myModal16" class="offer-img">
                                    <img src="images/of15.png" class="img-responsive" alt="" />
                                    <div class="offer"><p><span>Offer</span></p></div>
                                </a>
                                <div class="mid-1">
                                    <div class="women">
                                        <h6><a href="single.html">Bread</a>(500 g)</h6>							
                                    </div>
                                    <div class="mid-2">
                                        <p ><label>$1.00</label><em class="item_price">$0.80</em></p>
                                        <div class="block">
                                            <div class="starbox small ghosting"> </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="add">
                                        <button class="button-outline my-cart-btn my-cart-b" data-id="16" data-name="Bread" data-summary="summary 16" data-price="0.80" data-quantity="1" data-image="images/of15.png">Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                }
            </div>
        </div>
    
        </div>
	</div>
    
    </React.Fragment>
    )
}
