import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './Header'
import Footer from './Footer'
import Home from './Home'


export default class App extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <Home />
                <Footer />
            </React.Fragment>
        )
    }
}
