import React from 'react'
// import MainMenu from './MainMenu'


export default function index() {
    return (
        <div class="header">

		<div class="container-fluid">
			<div class="logo col-md-4 col-sm-12">
				<h1 ><a href="/"><b>T<br/>H<br />E</b>Leo-Store<span>Online-store Solution</span></a></h1>
			</div>

			<div class="head-t col-md-4 col-sm-12">
				<ul class="card">
					<li><a href="wishlist.html" ><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a></li>
					<li><a href="login.html" ><i class="fa fa-user" aria-hidden="true"></i>Login</a></li>
					<li><a href="register.html" ><i class="fa fa-arrow-right" aria-hidden="true"></i>Register</a></li>
					<li><a href="about.html" ><i class="fa fa-file-text-o" aria-hidden="true"></i>Orders</a></li>
					<li><a href="shipping.html" ><i class="fa fa-ship" aria-hidden="true"></i>Shipping</a></li>
				</ul>	
			</div>
			
            <div class="search-form  col-md-4 col-sm-12">
                <input type="text" class="form-control" />
                <input type="submit" value=""></input>
            </div>
            
			{/* <div class="header-ri">
				<ul class="social-top">
					<li><a href="#" class="icon facebook"><i class="fa fa-facebook" aria-hidden="true"></i><span></span></a></li>
					<li><a href="#" class="icon twitter"><i class="fa fa-twitter" aria-hidden="true"></i><span></span></a></li>
					<li><a href="#" class="icon pinterest"><i class="fa fa-pinterest-p" aria-hidden="true"></i><span></span></a></li>
					<li><a href="#" class="icon dribbble"><i class="fa fa-dribbble" aria-hidden="true"></i><span></span></a></li>
				</ul>	
			</div> */}
		
            {/* <MainMenu /> */}

            </div>		    	
</div>
    )
}
